<template>
  <div>
    <div class="mx-auto custom-container" v-if="step === 1" style="width: 40%">
      <h3 class="pb-3 px-1 mb-0 main-title text-primary">Registration</h3>
      <CCard class="mt-2 shadow-lg">
        <CCardBody>
          <CRow class="text-center">
            <label class="text-primary h5 font-weight-bold mt-2 ml-3">
              Please create your user email ID
            </label>
          </CRow>
          <CRow md="3" sm="12" class="text-left ml-1 mt-2">
            <label>Enter Your Email</label>
          </CRow>
          <CRow md="6" sm="12">
            <div class="col-12">
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <TextInput
                  name="email"
                  :value="existingCandidate.email"
                  @input="handleExistingCandidateInput"
                  :error="errors[0]"
                  autocomplete="login-email"
                  ref="existingCandidate"
                />
              </ValidationProvider>
            </div>
          </CRow>
          <CRow md="3" sm="12" class="text-left ml-1 mt-2">
            <label>Confirm Your Email</label>
          </CRow>
          <CRow md="6" sm="12">
            <div class="col-12">
              <ValidationProvider
                rules="required|email|emailConfirmed:email"
                v-slot="{ errors }"
              >
                <TextInput
                  name="confirm_email"
                  :value="existingCandidate.confirm_email"
                  @input="handleExistingCandidateInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </CRow>
          <CRow class="mt-3 text-center">
            <CCol md="12" sm="12">
              <CButton class="btn-secondary" @click="$router.push('/tfw')">
                Back
              </CButton>
              <CButton
                name="registration-submit"
                class="btn-primary ml-4"
                type="submit"
                ref="submitButton"
                :disabled="isLoading"
                @click="checkCandidateExists()"
              >
                Next
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div
      v-if="step === 2"
      class="d-flex justify-content-center align-items-center"
    >
      <CCard style="width: 500px">
        <CCardBody>
          <CCardTitle
            class="text-primary text-center h2 mb-4"
            style="font-weight: 500"
          >
            Welcome To PRSTG
          </CCardTitle>
          <h3 class="mb-2">
            Hello
            <span class="text-primary">
              {{ (userInfo.candidate_name || "") | toTitleCase }}!
            </span>
          </h3>
          <CRow class="mb-3">
            <CCol md="12" class="font-weigth-bold mb-2">
              Please enter your Password
            </CCol>
            <CCol>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                ref="existingCandidatePassword"
              >
                <TextInput
                  name="password"
                  type="password"
                  :value="existingCandidate.password"
                  @input="handleExistingCandidateInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="text-left">
              <CButton color="link" class="px-0">Forgot password?</CButton>
            </CCol>
          </CRow>
          <CRow class="d-flex justify-content-center">
            <CButton class="btn-secondary mr-3" @click="$router.push('/tfw')"
              >Back</CButton
            >
            <CButton class="btn-primary">Login</CButton>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { extend } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import { required, email, confirmed } from "vee-validate/dist/rules";
extend("emailConfirmed", {
  ...confirmed,
  message:
    "The email addresses entered do not match. Please correct and try again.",
});
import TextInput from "@/components/reusable/Fields/TextInput";

export default {
  components: {
    TextInput,
  },
  data() {
    return {
      existingCandidate: {},
      isLoading: false,
      userInfo: {},
      isAlreadyRegistered: false,
      step: 1,
    };
  },
  methods: {
    ...mapActions(["showToast", "getRegisteredHospitalsByEmail"]),
    handleExistingCandidateInput(name, value) {
      let val = value;
      if (name === "email" || name === "confirm_email")
        val = value?.toLowerCase().trim();
      Vue.set(this.existingCandidate, name, val);
    },
    checkCandidateExists() {
      this.isLoading = true;
      if (!this.existingCandidate["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.existingCandidate["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      if (
        this.existingCandidate["email"] ===
        this.existingCandidate["confirm_email"]
      ) {
        const payload = {
          email: this.existingCandidate["email"],
          customer_uid: 24,
          organisation_uid: this.organisationID || null,
          check_registered: true,
        };
        this.getRegisteredHospitalsByEmail(payload).then((res) => {
          this.isLoading = false;
          const errMsg = res?.data?.detail?.message || res?.data?.detail;
          if (res?.status === 409) {
            let message = errMsg;
            this.showToast({
              class: "bg-danger text-white",
              message,
            });
          } else if (res?.status === 200) {
            this.userInfo = {
              candidate_name: res?.data[0]?.candidate_name,
            };
            this.isAlreadyRegistered = true;
            this.step = 2;
          }
        });
      } else {
        this.isLoading = false;
        return false;
      }
    },
  },
  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    },
  },
};
</script>
